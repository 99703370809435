import HttpService from "./http.service";

class CmsService extends HttpService {
    listNewsChildCategories = (params) => this.get("cms/list-child-categories", params); 

    list = (params) => this.get("cms/list-media-news", params);

    listMore = (params) => this.get("cms/list-more-news", params);

    newsDetail = (params) => this.get("cms/news-detail", params);

    listProject = (params) => this.get("cms/list-project", params);

    projectDetail = (params) => this.get("cms/project-detail", params);

    sportDetail = (params) => this.get("cms/sport-detail", params);

    listCatagory = (params) => this.get("cms/get-gallery-category", params);

    home = () => this.get("cms/list-home-data");

    aboutUsData = (params) => this.get("cms/list-about-child", params);

    projChildCat = (params) => this.get("cms/get-project-child-cat", params);

    kalaBaghData = (params) => this.get("cms/list-kalabagh-episodes", params);

    listEmpCorner = (params) => this.get("cms/list-emp-corner", params);
}

export default new CmsService();