import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  newsChildCategoryList: null,
  newsChildCategoryListError: null,
  moreNewsLoading: false,
  newsList: null,
  moreNewsList: null,
  newsDetail: null,
  projectList: null,
  projectDetail: null,
  sportDetail: null,
  relProjectData: null,
  relNewsData:null,
  homeData:null,
  projectChildCategories: null,
  KBData:null,
  empCornerData:null,
  data:null,
  error: null,
  success: null
};
export const cmsReducer = (state = initialState, action) => {
  // console.log(action,"action",state)
  switch (action.type) {
    case actionTypes.GET_NEWS_CHILD_CATEGORY_LIST_START:
      return { ...state, loading: true, newsChildCategoryListError: null, newsChildCategoryList: null };
    case actionTypes.GET_NEWS_CHILD_CATEGORY_LIST_SUCCESS:
      return { ...state, loading: false, newsChildCategoryListError: null, newsChildCategoryList: action.payload };
    case actionTypes.GET_NEWS_CHILD_CATEGORY_LIST_FAILURE:
      return { ...state, loading: false, newsChildCategoryListError: action.payload, newsChildCategoryList: null };

    case actionTypes.GET_NEWS_LIST_START:
      return { ...state, loading: true, error: null, success: null, newsList: null };

    case actionTypes.GET_NEWS_LIST_SUCCESS:
      return {...state, loading: false, error: null, success: null, newsList: action.payload, };
  
    case actionTypes.GET_NEWS_LIST_FAILURE:
      return { ...state, loading: false, error: action.payload, success: null, };

    case actionTypes.GET_MORE_NEWS_LIST_START:
      return { ...state, moreNewsLoading: true, error: null, success: null, moreNewsList: null };

    case actionTypes.GET_MORE_NEWS_LIST_SUCCESS:
      return {...state, moreNewsLoading: false, error: null, success: null, moreNewsList: action.payload, };
  
    case actionTypes.GET_MORE_NEWS_LIST_FAILURE:
      return { ...state, moreNewsLoading: false, error: action.payload, success: null, };

    /** News Details */
    case actionTypes.GET_NEWS_DETAIL_START:
      return { ...state, loading: true, error: null, success: null, newsDetail: null };

    case actionTypes.GET_NEWS_DETAIL_SUCCESS:
      return {...state, loading: false, error: null, success: null, newsDetail: action.payload, };
  
    case actionTypes.GET_NEWS_DETAIL_FAILURE:
      return { ...state, loading: false, error: action.payload, success: null, };
    
    /** Projects */
    case actionTypes.GET_PROJECT_LIST_START:
      return { ...state, loading: true, error: null, success: null, projectList: null };

    case actionTypes.GET_PROJECT_LIST_SUCCESS:
      return {...state, loading: false, error: null, success: null, projectList: action.payload, };
  
    case actionTypes.GET_PROJECT_LIST_FAILURE:
      return { ...state, loading: false, error: action.payload, success: null, };
    
    case actionTypes.GET_PROJECT_DETAIL_START:
      return { ...state, loading: true, error: null, success: null, projectDetail: null };

    case actionTypes.GET_PROJECT_DETAIL_SUCCESS:
      return {...state, loading: false, error: null, success: true, projectDetail: action.payload, };
  
    case actionTypes.GET_PROJECT_DETAIL_FAILURE:
      return { ...state, loading: false, error: action.payload, success: null, };

    /** Sports Detail */
    
    case actionTypes.GET_SPORT_DETAIL_START:
      return { ...state, loading: true, error: null, success: null, sportDetail: null };

    case actionTypes.GET_SPORT_DETAIL_SUCCESS:
      return {...state, loading: false, error: null, success: null, sportDetail: action.payload, };
  
    case actionTypes.GET_SPORT_DETAIL_FAILURE:
      return { ...state, loading: false, error: action.payload, success: null, };
    
    /** Home */
    case actionTypes.GET_HOME_DATA_START:
      return { ...state, loading: true, error: null, success: null, relProjectData: null,relNewsData:null};

    case actionTypes.GET_HOME_DATA_SUCCESS:
      return {...state, loading: false, error: null, success: null, relProjectData: action.payload?.projectData, relNewsData:action.payload?.latestNews,homeData:action.payload?.homeData};
  
    case actionTypes.GET_HOME_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload, success: null, };

    
    /** WAPDA REGULATION ABOUT US CHILD */
    case actionTypes.GET_ABOUT_US_CHILD_START:
      return { ...state, loading: true, error: null, success: null, data: null };

    case actionTypes.GET_ABOUT_US_CHILD_SUCCESS:
      return {...state, loading: false, error: null, success: null, data: action.payload, };
  
    case actionTypes.GET_ABOUT_US_CHILD_FAILURE:
      return { ...state, loading: false, error: action.payload, success: null, };    

    /** Projects Child Categories */
    case actionTypes.GET_PROJECT_CHILD_CATEGORY_START:
      return { ...state, loading: true, error: null, success: null, projectChildCategories: null };

    case actionTypes.GET_PROJECT_CHILD_CATEGORY_SUCCESS:
      return {...state, loading: false, error: null, success: null, projectChildCategories: action.payload, };
  
    case actionTypes.GET_PROJECT_CHILD_CATEGORY_FAILURE:
      return { ...state, loading: false, error: action.payload, success: null, };
    // End

    case actionTypes.GET_KB_DATA_START:
      return { ...state, loading: true, error: null, success: null, KBData: null };

    case actionTypes.GET_KB_DATA_SUCCESS:
      return {...state, loading: false, error: null, success: null, KBData: action.payload, };
  
    case actionTypes.GET_KB_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload, success: null, };    
    
    /** List Emp Corner */
    case actionTypes.GET_LIST_EMP_CORNER_START:
      return { ...state, loading: true, error: null, success: null, empCornerData: null };

    case actionTypes.GET_LIST_EMP_CORNER_SUCCESS:
      return {...state, loading: false, error: null, success: null, empCornerData: action.payload, };
  
    case actionTypes.GET_LIST_EMP_CORNER_FAILURE:
      return { ...state, loading: false, error: action.payload, success: null, };
    // End

    case actionTypes.CMS_MESSAGE_HANDLER:
      return { ...state, loading: false, error: action.payload,  success: action.payload,
    };

    default: 
      return {
        ...state // Returning default state which is already set in reducer instead of resetting
        // loading: false,
        // moreNewsLoading: false,
        // newsList: null,
        // moreNewsList: null,
        // newsDetail: null,
        // projectList: null,
        // projectDetail: null,
        // sportDetail: null,
        // // relProjectData: null,
        // // relNewsData:null,
        // projectChildCategories: null,
        // empCornerData: null,
        // error: null,
        // success: null
      };
  }
};
