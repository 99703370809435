// Users Action Types
export const GET_USERS_LIST_START = "GET_USERS_LIST_START";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_FAILURE = "GET_USERS_LIST_FAILURE";
export const SET_USER_SERVICES_LIST = "USER_SERVICES_LIST";
export const GET_USER_DATA_START = "GET_USER_DATA_START";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_FAILURE = "GET_USER_DATA_ERROR";

export const ADD_NEW_USER_START = "ADD_NEW_USER_START";
export const ADD_NEW_USER_SUCCESS = "ADD_NEW_USER_SUCCESS";
export const ADD_NEW_USER_FAILURE = "ADD_NEW_USER_FAILURE";

export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const DELETE_USER_START = "DELETE_USER_START";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const SEND_OTP_START = "SEND_OTP_START";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAILURE = "SEND_OTP_ERROR";

export const ADD_OTP_START = "ADD_OTP_START";
export const ADD_OTP_SUCCESS = "ADD_OTP_SUCCESS";
export const ADD_OTP_FAILURE = "ADD_OTP_ERROR";

export const SEND_QUERY_START = "SEND_QUERY_START";
export const SEND_QUERY_SUCCESS = "SEND_QUERY_SUCCESS";
export const SEND_QUERY_FAILURE = "SEND_QUERY_ERROR";

export const GET_CONFIG_START = "GET_CONFIG_START";
export const GET_CONFIG_SUCCESS = "GET_CONFIG_SUCCESS";
export const GET_CONFIG_FAILURE = "GET_CONFIG_FAILURE";

export const GET_VIEWS_START = "GET_VIEWS_START";
// export const GET_VIEWS_SUCCESS = "GET_VIEWS_SUCCESS";
export const GET_VIEWS_FAILURE = "GET_VIEWS_FAILURE";

// Auction Action Types
export const GET_AUCTION_LIST_START = "GET_AUCTION_LIST_START";
export const GET_AUCTION_LIST_SUCCESS = "GET_AUCTION_LIST_SUCCESS";
export const GET_AUCTION_LIST_FAILURE = "GET_AUCTION_LIST_FAILURE";
export const GET_AUCTION_DATA_START = "GET_AUCTION_DATA_START";
export const GET_AUCTION_DATA_SUCCESS = "GET_AUCTION_DATA_SUCCESS";
export const GET_AUCTION_DATA_FAILURE = "GET_AUCTION_DATA_ERROR";

export const GET_PAYMENT_DATA_START = "GET_PAYMENT_DATA_START";
export const GET_PAYMENT_DATA_SUCCESS = "GET_PAYMENT_DATA_SUCCESS";
export const GET_PAYMENT_DATA_FAILURE = "GET_PAYMENT_DATA_ERROR";

export const CREATE_AUCTION_START = "CREATE_AUCTION_START";
export const CREATE_AUCTION_SUCCESS = "CREATE_AUCTION_SUCCESS";
export const CREATE_AUCTION_FAILURE = "CREATE_AUCTION_ERROR";

/** Tender Action Types */
export const GET_TENDER_LIST_START = "GET_TENDER_LIST_START";
export const GET_TENDER_LIST_SUCCESS = "GET_TENDER_LIST_SUCCESS";
export const GET_TENDER_LIST_FAILURE = "GET_TENDER_LIST_ERROR";
export const TENDER_MESSAGE_HANDLER = "TENDER_MESSAGE_HANDLER";

/** Asset Action Types */
export const GET_CATEGORY_LIST_START = "GET_CATEGORY_LIST_START";
export const GET_CATEGORY_LIST_SUCCESS = "GET_CATEGORY_LIST_SUCCESS";
export const GET_CATEGORY_LIST_FAILURE = "GET_CATEGORY_LIST_ERROR";
export const GET_GALLERY_CATEGORY_LIST_START = "GET_GALLERY_CATEGORY_LIST_START";
export const GET_GALLERY_CATEGORY_LIST_SUCCESS = "GET_GALLERY_CATEGORY_LIST_SUCCESS";
export const GET_GALLERY_CATEGORY_LIST_FAILURE = "GET_GALLERY_CATEGORY_LIST_ERROR"
export const GET_CHILD_CATEGORY_LIST_START = "GET_CHILD_CATEGORY_LIST_START";
export const GET_CHILD_CATEGORY_LIST_SUCCESS = "GET_CHILD_CATEGORY_LIST_SUCCESS";
export const GET_CHILD_CATEGORY_LIST_FAILURE = "GET_CHILD_CATEGORY_LIST_ERROR";
export const GET_GALLERY_LIST_START = "GET_GALLERY_LIST_START";
export const GET_GALLERY_LIST_SUCCESS = "GET_GALLERY_LIST_SUCCESS";
export const GET_GALLERY_LIST_FAILURE = "GET_GALLERY_LIST_ERROR";
export const VIDEO_HIT_START = "VIDEO_HIT_START";
export const VIDEO_HIT_SUCCESS = "VIDEO_HIT_SUCCESS";
export const VIDEO_HIT_FAILURE = "VIDEO_HIT_ERROR";
export const CATEGORY_MESSAGE_HANDLER = "CATEGORY_MESSAGE_HANDLER"; 

/** CMS Action Types */
export const GET_NEWS_CHILD_CATEGORY_LIST_START = "GET_NEWS_CHILD_CATEGORY_LIST_START";
export const GET_NEWS_CHILD_CATEGORY_LIST_SUCCESS = "GET_NEWS_CHILD_CATEGORY_LIST_SUCCESS";
export const GET_NEWS_CHILD_CATEGORY_LIST_FAILURE = "GET_NEWS_CHILD_CATEGORY_LIST_FAILURE";
export const GET_NEWS_LIST_START = "GET_NEWS_LIST_START";
export const GET_NEWS_LIST_SUCCESS = "GET_NEWS_LIST_SUCCESS";
export const GET_NEWS_LIST_FAILURE = "GET_NEWS_LIST_ERROR";
export const GET_MORE_NEWS_LIST_START = "GET_MORE_NEWS_LIST_START";
export const GET_MORE_NEWS_LIST_SUCCESS = "GET_MORE_NEWS_LIST_SUCCESS";
export const GET_MORE_NEWS_LIST_FAILURE = "GET_MORE_NEWS_LIST_ERROR";
export const GET_NEWS_DETAIL_START = "GET_NEWS_DETAIL_START";
export const GET_NEWS_DETAIL_SUCCESS = "GET_NEWS_DETAIL_SUCCESS";
export const GET_NEWS_DETAIL_FAILURE = "GET_NEWS_DETAIL_ERROR";
export const GET_PROJECT_LIST_START = "GET_PROJECT_LIST_START";
export const GET_PROJECT_LIST_SUCCESS = "GET_PROJECT_LIST_SUCCESS";
export const GET_PROJECT_LIST_FAILURE = "GET_PROJECT_LIST_ERROR";
export const GET_PROJECT_DETAIL_START = "GET_PROJECT_DETAIL_START";
export const GET_PROJECT_DETAIL_SUCCESS = "GET_PROJECT_DETAIL_SUCCESS";
export const GET_PROJECT_DETAIL_FAILURE = "GET_PROJECT_DETAIL_ERROR";
export const GET_SPORT_DETAIL_START = "GET_SPORT_DETAIL_START";
export const GET_SPORT_DETAIL_SUCCESS = "GET_SPORT_DETAIL_SUCCESS";
export const GET_SPORT_DETAIL_FAILURE = "GET_SPORT_DETAIL_ERROR";
export const GET_HOME_DATA_START = "GET_HOME_DATA_START";
export const GET_HOME_DATA_SUCCESS = "GET_HOME_DATA_SUCCESS";
export const GET_HOME_DATA_FAILURE = "GET_HOME_DATA_ERROR";
export const CMS_MESSAGE_HANDLER = "CMS_MESSAGE_HANDLER"; 
export const GET_ABOUT_US_CHILD_START = "GET_ABOUT_US_CHILD_START";
export const GET_ABOUT_US_CHILD_SUCCESS = "GET_ABOUT_US_CHILD_SUCCESS";
export const GET_ABOUT_US_CHILD_FAILURE = "GET_ABOUT_US_CHILD_FAILURE";
export const GET_PROJECT_CHILD_CATEGORY_START = "GET_PROJECT_CHILD_CATEGORY_START";
export const GET_PROJECT_CHILD_CATEGORY_SUCCESS = "GET_PROJECT_CHILD_CATEGORY_SUCCESS";
export const GET_PROJECT_CHILD_CATEGORY_FAILURE = "GET_PROJECT_CHILD_CATEGORY_FAILURE";
export const GET_KB_DATA_START = "GET_KB_DATA_START";
export const GET_KB_DATA_SUCCESS = "GET_KB_DATA_SUCCESS";
export const GET_KB_DATA_FAILURE = "GET_KB_DATA_FAILURE";
export const GET_LIST_EMP_CORNER_START = "GET_LIST_EMP_CORNER_START";
export const GET_LIST_EMP_CORNER_SUCCESS = "GET_LIST_EMP_CORNER_SUCCESS";
export const GET_LIST_EMP_CORNER_FAILURE = "GET_LIST_EMP_CORNER_FAILURE";

//Riverflow
export const GET_RIVERFLOWS_PAK_START = "GET_RIVERFLOWS_PAK_START"; 
export const GET_RIVERFLOW_LIST_SUCCESS = "GET_RIVERFLOW_LIST_SUCCESS";
export const GET_RIVERFLOW_LIST_FAILURE = "GET_RIVERFLOW_LIST_FAILURE";
export const RIVERFLOW_MESSAGE_HANDLER = "RIVERFLOW_MESSAGE_HANDLER";

export const MESSAGE_HANDLER = "MESSAGE_HANDLER"; 

export const SIGNUP_START = "SIGNUP_START";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGIN_ERROR_HANDLER_SUCCESS = "LOGIN_ERROR_HANDLER_SUCCESS";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";


export const DASHBOARD_START = "DASHBOARD_START";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";
export const DASHBOARD_ERROR_HANDLER_SUCCESS = "DASHBOARD_ERROR_HANDLER_SUCCESS";

export const CONFIRMATION_MODAL = "CONFIRMATION_MODAL";
export const HELP_HANDLER = "HELP_HANDLER";