import * as actionTypes from "../actionTypes";

export const getNewsChildCategoryList = (data) => {
    return {
        type: actionTypes.GET_NEWS_CHILD_CATEGORY_LIST_START,
        payload: data,
    };
};
export const getNewsChildCategoryListSuccess = (data) => {
    return {
        type: actionTypes.GET_NEWS_CHILD_CATEGORY_LIST_SUCCESS,
        payload: data,
    };
};
export const getNewsChildCategoryListFailure = (data) => {
    return {
        type: actionTypes.GET_NEWS_CHILD_CATEGORY_LIST_FAILURE,
        payload: data,
    };
};

export const getNewsList = (data) => {
    return {
        type: actionTypes.GET_NEWS_LIST_START,
        payload: data,
    };
};
export const getNewsListSuccess = (data) => {
    return {
        type: actionTypes.GET_NEWS_LIST_SUCCESS,
        payload: data,
    };
};
export const getNewsListFailure = (data) => {
    return {
        type: actionTypes.GET_NEWS_LIST_FAILURE,
        payload: data,
    };
};

export const getMoreNewsList = (data) => {
    return {
        type: actionTypes.GET_MORE_NEWS_LIST_START,
        payload: data,
    };
};
export const getMoreNewsListSuccess = (data) => {
    return {
        type: actionTypes.GET_MORE_NEWS_LIST_SUCCESS,
        payload: data,
    };
};
export const getMoreNewsListFailure = (data) => {
    return {
        type: actionTypes.GET_MORE_NEWS_LIST_FAILURE,
        payload: data,
    };
};

export const getNewsDetail = (data) => {
    return {
        type: actionTypes.GET_NEWS_DETAIL_START,
        payload: data,
    };
};
export const getNewsDetailSuccess = (data) => {
    return {
        type: actionTypes.GET_NEWS_DETAIL_SUCCESS,
        payload: data,
    };
};
export const getNewsDetailFailure = (data) => {
    return {
        type: actionTypes.GET_NEWS_DETAIL_FAILURE,
        payload: data,
    };
};

// Project
export const getProjectList = (data) => {
    return {
        type: actionTypes.GET_PROJECT_LIST_START,
        payload: data,
    };
};
export const getProjectListSuccess = (data) => {
    return {
        type: actionTypes.GET_PROJECT_LIST_SUCCESS,
        payload: data,
    };
};
export const getProjectListFailure = (data) => {
    return {
        type: actionTypes.GET_PROJECT_LIST_FAILURE,
        payload: data,
    };
};

export const getProjectDetail = (data) => {
    return {
        type: actionTypes.GET_PROJECT_DETAIL_START,
        payload: data,
    };
};
export const getProjectDetailSuccess = (data) => {
    return {
        type: actionTypes.GET_PROJECT_DETAIL_SUCCESS,
        payload: data,
    };
};
export const getProjectDetailFailure = (data) => {
    return {
        type: actionTypes.GET_PROJECT_DETAIL_FAILURE,
        payload: data,
    };
};

// Sports

export const getSportDetail = (data) => {
    return {
        type: actionTypes.GET_SPORT_DETAIL_START,
        payload: data,
    };
};
export const getSportDetailSuccess = (data) => {
    return {
        type: actionTypes.GET_SPORT_DETAIL_SUCCESS,
        payload: data,
    };
};
export const getSportDetailFailure = (data) => {
    return {
        type: actionTypes.GET_SPORT_DETAIL_FAILURE,
        payload: data,
    };
};

// Home
export const getHomeData = () => {
    //console.log("getHomeData homedata",data);
    return {
        type: actionTypes.GET_HOME_DATA_START,
        payload: null,
    };
};
export const getHomeDataSuccess = (data) => {
    //console.log("getHomeDataSuccess= data ",data);
    return {
        type: actionTypes.GET_HOME_DATA_SUCCESS,
        payload: data,
    };
};
export const getHomeDataFailure = (data) => {
    return {
        type: actionTypes.GET_HOME_DATA_FAILURE,
        payload: data,
    };
};


// List Project Categories
export const getProjectChildCategory = (data) => {
    return {
        type: actionTypes.GET_PROJECT_CHILD_CATEGORY_START,
        payload: data,
    };
};
export const getProjectChildCategorySuccess = (data) => {
    return {
        type: actionTypes.GET_PROJECT_CHILD_CATEGORY_SUCCESS,
        payload: data,
    };
};
export const getProjectChildCategoryFailure = (data) => {
    return {
        type: actionTypes.GET_PROJECT_CHILD_CATEGORY_FAILURE,
        payload: data,
    };
};

export const cmsMessageHandler = () => { 
    return {
        type: actionTypes.CMS_MESSAGE_HANDLER,
        payload: null,
    };
};

export const getAboutUsChild = (data) => { 
    return {
        type: actionTypes.GET_ABOUT_US_CHILD_START,
        payload: data,
    };
};

export const getAboutUsChildSuccess = (data) => {
    return {
        type: actionTypes.GET_ABOUT_US_CHILD_SUCCESS,
        payload: data,
    };
};

export const getAboutUsChildFailure = (data) => {
    return {
        type: actionTypes.GET_ABOUT_US_CHILD_FAILURE,
        payload: data,
    };
};

export const getKBData = (data) => { 
    return {
        type: actionTypes.GET_KB_DATA_START,
        payload: data,
    };
};

export const getKBDataSuccess = (data) => {
    return {
        type: actionTypes.GET_KB_DATA_SUCCESS,
        payload: data,
    };
};

export const getKBDataFailure = (data) => {
    return {
        type: actionTypes.GET_KB_DATA_FAILURE,
        payload: data,
    };
};

/** List Employee Corner */
export const getListEmpCorner = (data) => {
    return {
        type: actionTypes.GET_LIST_EMP_CORNER_START,
        payload: data,
    };
};
export const getListEmpCornerSuccess = (data) => {
    return {
        type: actionTypes.GET_LIST_EMP_CORNER_SUCCESS,
        payload: data,
    };
};
export const getListEmpCornerFailure = (data) => {
    return {
        type: actionTypes.GET_LIST_EMP_CORNER_FAILURE,
        payload: data,
    };
};
