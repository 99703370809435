import { ASSET_BASE_URL } from '../../environment';

const userIcon = ASSET_BASE_URL + 'images/userIcon.png';
const playBan = ASSET_BASE_URL + 'images/playBan.png';
const GetAppIcon = '@material-ui/icons/GetApp';
const CloudUploadOutlinedIcon = '@material-ui/icons/CloudUploadOutlined';
const ClearIcon = '@material-ui/icons/Clear';
const EditIcon = "@material-ui/icons/Edit";
const ExpandMoreIcon = '@material-ui/icons/ExpandMore';
const ExpandLessIcon = '@material-ui/icons/ExpandLess';

const BGImageHome = ASSET_BASE_URL + 'images/BG-Image-Home.jpg';
const BGImageProjects = ASSET_BASE_URL + 'images/BG-Image-Projects.jpg';
const PlayButton = ASSET_BASE_URL + 'images/vidplay-sm.svg';
const PauseButton = ASSET_BASE_URL + 'images/vidpause-sm.svg';
const Image15 = ASSET_BASE_URL + 'images/kazuend-cCthPLHmrzI-unsplash-1.png';
const Image16 = ASSET_BASE_URL + 'images/Hydro-Reservoir.png';
const Image17 = ASSET_BASE_URL + 'images/Hydro-Pow-Gen.png';
const archive = ASSET_BASE_URL + 'images/achive.jpg';
const bgLines = ASSET_BASE_URL + 'images/bg-lines-circle-2.png';

/** Media News */
const wapdaBannerBG = ASSET_BASE_URL + 'images/wapdaBannerBG.jpg'

const downloadIcon = ASSET_BASE_URL + 'images/download.svg'
const timeOClokIcon = ASSET_BASE_URL + 'images/vector.svg'
const forwardTimeIcon = ASSET_BASE_URL + 'images/fi-rr-time-forward.svg'

const ItemTick = ASSET_BASE_URL + 'images/Item-Tick.png';

const globeInvestor = ASSET_BASE_URL + 'images/globe-invester.png';
const investorBestBg = ASSET_BASE_URL + 'images/investerbest-bg.jpg';
const messageCcBgMbl = ASSET_BASE_URL + 'images/message-cc-bg-mbl.jpg';
const messageCcBg = ASSET_BASE_URL + 'images/message-cc-bg.jpg';
const listCircleBorder = ASSET_BASE_URL + 'images/listcircle-border.svg';

///////about-us////
const Dam = ASSET_BASE_URL + 'images/Dam.png';
const AboutUsBanner = ASSET_BASE_URL + 'images/about-us-banner.jpg';
const AboutBanner = ASSET_BASE_URL + 'images/about-banner.png';
const AboutMaskIMage1 = ASSET_BASE_URL + 'images/About-Image-1.png';
const AboutMaskIMage2 = ASSET_BASE_URL + 'images/About-Image-2.png';
const WapdaOrg = ASSET_BASE_URL + 'images/wapda-organization.jpg';
const Cash = ASSET_BASE_URL + 'images/cash 1.png';
const ThunderBolt = ASSET_BASE_URL + 'images/thunderbolt 2.png';
const Sample1 = ASSET_BASE_URL + 'images/sample 1.png';
const User1 = ASSET_BASE_URL + 'images/user 1 (1).png'
const TickCheck = ASSET_BASE_URL + 'images/path0.png'

/////** present setup */
const presentBanner = ASSET_BASE_URL + 'images/presentbanner-tp.jpg';
const presentImage = ASSET_BASE_URL + 'images/present-image.png';
const presentImageCard = ASSET_BASE_URL + 'images/present-image2.png';
const presentImage1 = ASSET_BASE_URL + 'images/present-image4-new.png'
/**VEDIO GALLERY */
const videoGalleryBanner = ASSET_BASE_URL + 'images/videoGalleryBanner.jpg';

/** IMAGE GALLERY */
const imageGalleryBanner = ASSET_BASE_URL + 'images/imageGalleryBanner.jpg';

//////***onoGRAM Images */
const OrganogramBanner = ASSET_BASE_URL + 'images/organogramBanner.jpg';
const OrganoGram = ASSET_BASE_URL + 'images/OrganoGram.jpg';

////** careers */
const careersBanner = ASSET_BASE_URL + 'images/careersBanner.jpg';
const careerDownload = ASSET_BASE_URL + 'images/careerdownload.svg'


//////***Investors List Icon Images */
const RadiusRoundTick = ASSET_BASE_URL + 'images/RadiusRoundTick.svg';

const disclaimerIcon = ASSET_BASE_URL + 'images/disclaimerlist.svg';
const eLibrary = ASSET_BASE_URL + 'images/library.svg'
const docIcon = ASSET_BASE_URL + 'images/doc 1.png';
const infoIcon = ASSET_BASE_URL + 'images/info.svg'

//////*** Get In Touc Footer Investors */
const getInTouchFooter = ASSET_BASE_URL + 'images/getInTouchFooter.png';

//////*** What We do Heading Icons */
const whatWeDo1 = ASSET_BASE_URL + 'images/whatWeDo1.png';
const whatWeDoBanner = ASSET_BASE_URL + 'images/whatWeDoBanner.png';

//////*** Wapda Regulations */
const wapdaRegulations = ASSET_BASE_URL + 'images/wapdaRegulations.jpg';

//////*** Wapda Regulations Banners */
const kalabaghBanner = ASSET_BASE_URL + 'images/kalabaghBanner.jpg';
const eLibraryBanner = ASSET_BASE_URL + 'images/eLibraryBanner.jpg';
const researchPaperBanner = ASSET_BASE_URL + 'images/researchPaperBanner.jpg';

/** Investor Tarbela */
const investorTarbela = ASSET_BASE_URL + 'images/investor-tarbela.png'
const investorMohmmand = ASSET_BASE_URL + 'images/investor-mohmmand.png'
const investorDiamar = ASSET_BASE_URL + 'images/investor-diamer.png'
const investorDasu = ASSET_BASE_URL + 'images/investor-dasu.jpg'
const investorDasu2 = ASSET_BASE_URL + 'images/investorDasu2.png'

const Contact = ASSET_BASE_URL + 'images/contactus.png';
const Phone = ASSET_BASE_URL + 'images/phone.svg';
const emailContact = ASSET_BASE_URL + 'images/email.svg';
const locationIcon = ASSET_BASE_URL + 'images/Address Icon.svg'

const educationBanner = ASSET_BASE_URL + 'images/educationCover.png';
const healthCare = ASSET_BASE_URL + 'images/healthcare.jpg';
const benificerBanner = ASSET_BASE_URL + 'images/Beneficiaries.png';

const Dispensaries = ASSET_BASE_URL + 'images/Dispensaries.png';
const Dental = ASSET_BASE_URL + 'images/dentalunit.png';
const wapdaHospital = ASSET_BASE_URL + 'images/wapdahospital.png';

const chairman = ASSET_BASE_URL + 'images/chairman.png'
const memberFinance = ASSET_BASE_URL + 'images/finance.png'
const memberPower = ASSET_BASE_URL + 'images/power.png'
const memberWater = ASSET_BASE_URL + 'images/water.png'

const hydelTariff = ASSET_BASE_URL + 'images/hydelTariff.png';
const annualReportImage = ASSET_BASE_URL + 'images/annualReport.png';
const financialInfo = ASSET_BASE_URL + 'images/financialInfo.png';
const generationLicense = ASSET_BASE_URL + 'images/generationLicense.png';

/** home */
const marketingBgBanner = ASSET_BASE_URL + 'images/marketing-bg-time.png';
const hoverproMohmandDamHome = ASSET_BASE_URL + 'images/Mohmand-Dam-home2.jpg';
const hoverproTarbelaDouble = ASSET_BASE_URL + 'images/Tarbela-Double-Spillways-home2.jpg';
const hoverproDBD = ASSET_BASE_URL + 'images/DBD-6-home.png';
const hoverproDasu = ASSET_BASE_URL + 'images/Dasu-2-home.png';
const hmcreatngimage1 = ASSET_BASE_URL + 'images/hmcreatngimage1.png';
const hmcreatngimage2 = ASSET_BASE_URL + 'images/hmcreatngimage2.png';
const hmcreatngimage3 = ASSET_BASE_URL + 'images/hmcreatngimage3.png';
const hmcreatngimage4 = ASSET_BASE_URL + 'images/hmcreatngimage4.png';

const Sports = ASSET_BASE_URL + 'images/sports2.jpg';
const homeBannerDam = ASSET_BASE_URL + 'images/homeBannerDam.jpg';
const thunderIcon = ASSET_BASE_URL + 'images/thunderIcon.svg';
const triangleIcon = ASSET_BASE_URL + 'images/triangleIcon.svg';
const waterLevel = ASSET_BASE_URL + 'images/waterLevelIcon.svg';
const waterTemp = ASSET_BASE_URL + 'images/waterTemp.svg';

const hydroReserviorBanner = ASSET_BASE_URL + 'images/hydro-reservoir-banner.png';

/** Wapda Act Banner */
const wapdaActBanner = ASSET_BASE_URL + 'images/wapdaActBanner.png';

const Download = GetAppIcon;
const UploadFile = CloudUploadOutlinedIcon;
export {
  userIcon, playBan,
  Download,
  UploadFile,
  ClearIcon,
  EditIcon,
  ExpandMoreIcon,
  ExpandLessIcon,

  BGImageHome,

  PlayButton,
  PauseButton,
  Image15,
  Image16,
  Image17,

  archive,
  bgLines,

  videoGalleryBanner,

  ///IMAGE GALLERY///
  imageGalleryBanner,
  wapdaBannerBG,

  downloadIcon, timeOClokIcon, forwardTimeIcon,

  BGImageProjects,
  ItemTick,

  globeInvestor,
  investorBestBg,
  messageCcBgMbl,
  messageCcBg,
  listCircleBorder,

  /////about us image//
  Dam,
  AboutUsBanner,
  AboutBanner,
  AboutMaskIMage1,
  AboutMaskIMage2,
  WapdaOrg,
  Cash,
  ThunderBolt,
  Sample1,
  User1,
  TickCheck,

  OrganogramBanner,
  OrganoGram,
  presentBanner,
  presentImage,
  presentImageCard,
  presentImage1,

  careersBanner,
  careerDownload,
  RadiusRoundTick,

  disclaimerIcon,
  eLibrary,
  docIcon,
  infoIcon,

  getInTouchFooter,

  whatWeDo1,
  whatWeDoBanner,

  wapdaRegulations,
  kalabaghBanner,
  eLibraryBanner,
  researchPaperBanner,
  Contact,
  Phone,
  emailContact,

  /** Investor Dams */
  investorDiamar, investorTarbela, investorMohmmand, investorDasu, investorDasu2,

  locationIcon,
  educationBanner,
  healthCare,
  benificerBanner,
  Dispensaries,
  Dental,
  wapdaHospital,

  chairman, memberFinance, memberPower, memberWater, hoverproMohmandDamHome, hoverproTarbelaDouble, hoverproDBD, hoverproDasu, marketingBgBanner,
  hmcreatngimage1,
  hmcreatngimage2,
  hmcreatngimage3,
  hmcreatngimage4,

  // Investors Page Investors' Section Images
  hydelTariff,
  annualReportImage,
  financialInfo,
  generationLicense,

  /// wapda sports image///
  Sports,
  homeBannerDam,
  thunderIcon, triangleIcon, waterLevel, waterTemp,

  hydroReserviorBanner,

  /** Wapda Act Banner */
  wapdaActBanner
}
