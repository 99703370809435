import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import {put, takeEvery, all, fork} from "redux-saga/effects";
import CmsService from '../../services/cms.service'


function* getNewsChildCategoryList(action) {
  try {
    console.log('getCategoryList action userSagas============ ',action);
    if (action.payload) {
      const category = action.payload?.catName;
      const status = action.payload?.statusType;
      let params = {category, status};

      const response = yield CmsService.listNewsChildCategories(params);
      console.log('getNewsChildCategoryList response userSagas============ ',response);
      if (response?.data.statusCode === 200) {
        console.log('response.data userSaga ',response.data);
        yield put(actions.getNewsChildCategoryListSuccess(response.data));
      } else {
        yield put(actions.getNewsChildCategoryListFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.getChildCategoryListFailure(error));
  }
}
function* getNewsChildCategoryListSaga() {
  yield takeEvery(actionsTypes.GET_NEWS_CHILD_CATEGORY_LIST_START, getNewsChildCategoryList);
}

function* getNewsList(action) {
  try {
    if (action.payload) {
      const type = action.payload.type;
      let params = {type};
      const response = yield CmsService.list(params);
      if (response?.data) {
        yield put(actions.getNewsListSuccess(response?.data));
      } else {
        yield put(actions.getNewsListFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'
    
    yield put(actions.getNewsListFailure(error));
  }
}
function* getNewsListSaga() {
  yield takeEvery(actionsTypes.GET_NEWS_LIST_START, getNewsList);
}

function* getMoreNewsList(action) {
  try {
    if (action.payload) {
      const page = action.payload?.pageNo;
      const perPage = action.payload?.perPage;
      const type = action.payload?.type;
      const category_id = action.payload?.category_id;
      let params = {page, perPage, type, category_id};
      // console.log("More News Saga PARAMS:===== ", params)
      const response = yield CmsService.listMore(params);
      // console.log('response ', response)
      if (response?.data) {
        yield put(actions.getMoreNewsListSuccess(response?.data));
      } else {
        yield put(actions.getMoreNewsListFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'
    
    yield put(actions.getMoreNewsListFailure(error));
  }
}
function* getMoreNewsListSaga() {
  yield takeEvery(actionsTypes.GET_MORE_NEWS_LIST_START, getMoreNewsList);
}

function* getNewsDetail(action) { /** News Details */
  try {
    if (action.payload) {
      const id = action.payload?.id;
      let params = {id};
      const response = yield CmsService.newsDetail(params);
      if (response?.data) {
        yield put(actions.getNewsDetailSuccess(response?.data));
      } else {
        yield put(actions.getNewsDetailFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'
    
    yield put(actions.getNewsDetailFailure(error));
  }
}
function* getNewsDetailSaga() {
  yield takeEvery(actionsTypes.GET_NEWS_DETAIL_START, getNewsDetail);
}

function* getProjectList(action) {
  try {
    if (action.payload) {
      const categoryId = action?.payload?.categoryId;
      const type = action?.payload?.type || ''; /** type:1 for Careers */
      let params = {categoryId, type};
      const response = yield CmsService.listProject(params);
      if (response?.data) {
        yield put(actions.getProjectListSuccess(response?.data));
      } else {
        yield put(actions.getProjectListFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'
    
    yield put(actions.getProjectListFailure(error));
  }
}
function* getProjectListSaga() {
  yield takeEvery(actionsTypes.GET_PROJECT_LIST_START, getProjectList);
}

function* getProjectDetail(action) { /** News Details */
  try {
    if (action.payload) {
      const id = action.payload?.id;
      let params = {id};
      const response = yield CmsService.projectDetail(params);
      if (response?.data) {
        yield put(actions.getProjectDetailSuccess(response?.data));
      } else {
        yield put(actions.getProjectDetailFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'
    
    yield put(actions.getProjectDetailFailure(error));
  }
}
function* getProjectDetailSaga() {
  yield takeEvery(actionsTypes.GET_PROJECT_DETAIL_START, getProjectDetail);
}

/** Sports Detail */

function* getSportDetail(action) { /** News Details */
  try {
    if (action.payload) {
      const id = action.payload?.id;
      let params = {id};
      const response = yield CmsService.sportDetail(params);
      if (response?.data) {
        yield put(actions.getSportDetailSuccess(response?.data));
      } else {
        yield put(actions.getSportDetailFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'
    
    yield put(actions.getSportDetailFailure(error));
  }
}
function* getSportDetailSaga() {
  yield takeEvery(actionsTypes.GET_SPORT_DETAIL_START, getSportDetail);
}

/** Home */
function* getHomeData(action) { /** News Details */
  try {
    if (action) {
      const response = yield CmsService.home();
      if (response?.data) {
        yield put(actions.getHomeDataSuccess(response?.data));
      } else {
        yield put(actions.getHomeDataFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'
    
    yield put(actions.getHomeDataFailure(error));
  }
}
function* getHomeDataSaga() {
  yield takeEvery(actionsTypes.GET_HOME_DATA_START, getHomeData);
}

/** Wapda Regulations */
function* getAboutUSChildData(action) {
  try {
    if (action.payload) {
      const page = action.payload?.pageNo;
      const perPage = action.payload?.perPage;
      const type = action.payload?.type;
      const category_id = action.payload?.category_id;
      const status = 1;
      let params = {page, perPage,category_id,type, status};
      const response = yield CmsService.aboutUsData(params);
      if (response?.data) {
        yield put(actions.getAboutUsChildSuccess(response?.data));
      } else {
        yield put(actions.getAboutUsChildFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'
    
    yield put(actions.getTenderListFailure(error));
  }
}
function* getAboutUsChlidSaga() {
  yield takeEvery(actionsTypes.GET_ABOUT_US_CHILD_START, getAboutUSChildData);
}

function* getProjectChildCategory(action) { /** Get Project Child Categories */
  try {
    if (action.payload) {
      const parentId = action.payload?.parentId;
      let params = { parentId };
      const response = yield CmsService.projChildCat(params);
      if (response?.data) {
        yield put(actions.getProjectChildCategorySuccess(response?.data));
      } else {
        yield put(actions.getProjectChildCategoryFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'
    
    yield put(actions.getProjectChildCategoryFailure(error));
  }
}
function* getProjectChildCategorySaga() {
  yield takeEvery(actionsTypes.GET_PROJECT_CHILD_CATEGORY_START, getProjectChildCategory);
}

function* getKalaBaghData(action) {
  try {
    if (action.payload) {
      const category_id = action.payload?.category_id;
      let params = {category_id};
      const response = yield CmsService.kalaBaghData(params);
      if (response?.data) {
        yield put(actions.getKBDataSuccess(response?.data));
      } else {
        yield put(actions.getKBDataFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'
    
    yield put(actions.getTenderListFailure(error));
  }
}
function* getKalaBaghDataSaga() {
  yield takeEvery(actionsTypes.GET_KB_DATA_START, getKalaBaghData);
}

function* getListEmpCorner(action) { /** List Employee Corner */
  try {
    if (action.payload) {
      const page = action?.payload?.pageNo;
      const perPage = action?.payload?.perPage;
      const category = action?.payload?.catId;
      const type = action?.payload?.type;
      const status = 1;
      let params = { page, perPage, category, status, type };
      const response = yield CmsService.listEmpCorner(params);
      if (response?.data) {
        yield put(actions.getListEmpCornerSuccess(response?.data));
      } else {
        yield put(actions.getListEmpCornerFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'
    
    yield put(actions.getListEmpCornerFailure(error));
  }
}
function* getListEmpCornerSaga() {
  yield takeEvery(actionsTypes.GET_LIST_EMP_CORNER_START, getListEmpCorner);
}

export function* cmsSaga() {
  yield all([
    fork(getNewsChildCategoryListSaga),
    fork(getNewsListSaga),
    fork(getMoreNewsListSaga),
    fork(getNewsDetailSaga),
    fork(getProjectListSaga),
    fork(getProjectDetailSaga),
    fork(getSportDetailSaga),
    fork(getHomeDataSaga),
    fork(getAboutUsChlidSaga),
    fork(getProjectChildCategorySaga),
    fork(getKalaBaghDataSaga),
    fork(getListEmpCornerSaga),
  ]);
}
